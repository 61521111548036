import { Auth } from 'aws-amplify';
import { mapMutations } from 'vuex';
export default {
  methods: {
    ...mapMutations({
      spinner: 'spinner',
      clearUserInfo: 'clearUserInfo',
      setUserData: 'UserProfile/setUserData',
      resetWorkspaceState: 'Workspace/resetState',
    }),
    async logout() {
      await this.$router.push({
        name: 'login',
      });
      this.spinner(this);
      try {
        Auth.signOut({
          global: true,
        });
      } finally {
        this.spinner(false);
        this.resetWorkspaceState();
        this.clearUserInfo();
        this.setUserData(null);
      }
    },
  },
};
